import { EUNOMIA_CASCADE_IMPORT, EUNOMIA_CASCADE_OPEN, EUNOMIA_CASCADE_POST_UPDATE } from '../constants';
import { eunomiaDomain, myId } from '../../../initial_state';
import axios from 'axios';
import { getUserPostVotes } from './vote';
import { getEunomiaHeaders } from '../lib';
import { updateTrustiness } from './post';

export function updateEunomiaCascadePost(post) {
  return { type: EUNOMIA_CASCADE_POST_UPDATE, post };
}

export function importEunomiaCascade(cascadeId, posts) {
  return { type: EUNOMIA_CASCADE_IMPORT, payload: { cascadeId, posts } };
}
export function setCascadeViewOpen(open) {
  return { type: EUNOMIA_CASCADE_OPEN, open };
}

const getFetchingCascades = () => {
  const entriesKey = sessionStorage.getItem('FCs');
  if (entriesKey) {
    try {
      return JSON.parse(entriesKey);
    } catch (e) {
      return [];
    }
  }
  return [];
};

const setFetchingCascade = (cascadeId) => {
  let cascadesFetching = getFetchingCascades();
  if (!cascadesFetching) {
    cascadesFetching = [];
  }
  if (!cascadesFetching.includes(cascadeId)) {
    cascadesFetching.push(cascadeId);
  }
  window.sessionStorage.setItem('FCs', JSON.stringify(cascadesFetching));
};

const removeFetchingCascade = (cascadeId) => {
  let cascadesFetching = getFetchingCascades();
  cascadesFetching = cascadesFetching.filter(entry => entry !== cascadeId);
  window.sessionStorage.setItem('FCs', JSON.stringify(cascadesFetching));
};

export const fetchCascade = (headers, cascadeId, dispatch, force=false) => {
  const cascadesFetching = getFetchingCascades();
  if (!cascadesFetching.includes(cascadeId) || force) {
    setFetchingCascade(cascadeId);
    const url = `https://${eunomiaDomain}/eunomia/api/cascades/${cascadeId}`;
    axios.get(url, { headers, timeout: 30000 }).then(({ data }) => {
      const postsToImport = [];
      data.forEach((post) => {
        getUserPostVotes(myId, post).then((_post) => {
          _post.trustiness = updateTrustiness(_post);
          postsToImport.push(_post);
          if (postsToImport.length === data.length) {
            removeFetchingCascade(cascadeId);
            dispatch(importEunomiaCascade(cascadeId, postsToImport));
          }
        }).catch(() => {
          postsToImport.push(post);
          if (postsToImport.length === data.length) {
            removeFetchingCascade(cascadeId);
            dispatch(importEunomiaCascade(cascadeId, postsToImport));
          }
        });
      });
    }).catch((reason) => {
      // eslint-disable-next-line no-console
      console.log(reason);
      removeFetchingCascade(cascadeId);
    });
  }
};


export const onCascadeResults = (cascadeId) => (dispatch) => {
  getEunomiaHeaders().then((headers) => {
    fetchCascade(headers, cascadeId, dispatch, true);
  }).catch(() => {});
};
