import { CURRENT_USER_VOTED, EUNOMIA_STATS_IMPORT, EUNOMIA_VOTING_INIT } from '../constants';
import voting from '../voting/lib';
import { accessToken, eunomiaDomain, myId } from '../../../initial_state';
import { getEunomiaHeaders, getEunomiaToken, plainDomain } from '../lib';
import axios from 'axios';
import { importEunomiaPost, importEunomiaPosts, setEunomiaPending, updateEunomiaCascadePost } from './index';
import nudge from '../nudge';

export const setVotingReinit = () => {
  if (window.sessionStorage.getItem(EUNOMIA_VOTING_INIT)) {
    window.sessionStorage.removeItem(EUNOMIA_VOTING_INIT);
  }
  voting.clear().then(()=>{}).catch(()=>{});
};


const getMyStats = (dispatch) => {
  const url = `https://${eunomiaDomain}/eunomia/api/me`;
  getEunomiaHeaders().then((headers) => {
    axios.get(url, { headers }).then((response) => {
      if (response.data && response.data.stats) {
        dispatch({ type: EUNOMIA_STATS_IMPORT, payload: response.data.stats });
      }
    }).catch(() =>dispatch(setEunomiaPending(url)));
  }).catch(() => dispatch(setEunomiaPending(url)));
};


export const getUserPostVotes = (userId, post) => {
  return new Promise((resolve) => {
    post.voted = false;
    post.trusted = null;
    if (post.source_id && post.source_url) {
      const postId = `${post.source_id}@${plainDomain(post.source_url)}`;
      voting.hasVoted(userId, postId)
        .then(({ message }) => {
          if (message) {
            const { voted, ballot } = message;
            if (voted !== undefined && ballot !== undefined) {
              const checkTrusted = ballot.votes !== undefined && ballot.votes.trust !== undefined && ballot.votes.no_trust !== undefined;
              // eslint-disable-next-line no-console
              // console.log(ballot);
              post.voted = checkTrusted ?? (voted && ballot.votes.trust !== ballot.votes.no_trust);
              post.trusted = checkTrusted ? ballot.votes.trust > ballot.votes.no_trust : null;
            }
          }
          resolve(post);
        }).catch(() => {
          resolve(post);
        });
    } else {
      resolve(post);
    }
  });
};

export const onSomeoneVoted = (post) => (dispatch) => {
  getEunomiaToken().then((data) => {
    if (data) {
      getMyStats(dispatch);
      if (post.source_id && post.source_url && post.votes) {
        getUserPostVotes(myId, post).then((_post) => {
          dispatch(importEunomiaPost(_post));
          if (_post.cascade_id) {
            dispatch(updateEunomiaCascadePost(_post));
          }
        }).catch(console.warn);
      }
    }
  }).catch();
};


export const onVPostResults = (posts) => (dispatch) => {
  try {
    if (accessToken !== null) {
      const postsToImport = [];
      posts.forEach((post, index) => {
        if (post.source_id && post.source_url) {
          getUserPostVotes(myId, post).then((_post) => {
            postsToImport.push(_post);
            if (index === posts.length - 1) {
              dispatch(importEunomiaPosts(postsToImport));
            }
          }).catch(() => {
            if (index === posts.length - 1) {
              dispatch(importEunomiaPosts(postsToImport));
            }
          });
        }
      });
    }
  } catch {}
};

const _notifyVoted = (sourceId, cb) => {
  const url = `https://${eunomiaDomain}/eunomia/api/voted/${sourceId}`;
  getEunomiaHeaders().then((headers) => {
    // eslint-disable-next-line promise/catch-or-return
    axios.get(url, { headers }).then(()=>{}).catch(()=>{}).finally(cb());
  }).catch(console.warn);
};

const _deleteVote = (post, token, cb) => {
  const sourceId = post.get('source_id', null);
  const sourceUrl = post.get('source_url', null);
  const postId = `${sourceId}@${plainDomain(sourceUrl)}`;
  voting.deleteVote(token, myId, postId).then(() => {
    cb(true);
  }).catch((reason) => {
    console.warn(reason);
    cb(false);
  });
};


const _normalVote = (token, trust, post, postId, sourceId, dispatch) => {
  voting.getBallot(token, postId, myId).then((response) => {
    let { ballot } = response.message;
    if (trust) {
      ballot.votes.trust = 1;
      ballot.votes.no_trust = 0;
    } else {
      ballot.votes.trust = 0;
      ballot.votes.no_trust = 1;
    }
    voting.vote(token, myId, { ballot }, false).then(() => {
      _notifyVoted(sourceId, () => dispatch({ type: CURRENT_USER_VOTED }));
    }).catch((e1) => {
      console.warn(e1);
      getUserPostVotes(myId, post).then((_post) => {
        dispatch(importEunomiaPost(_post));
      }).catch(() => {});
    });
  }).catch((e2) => {
    console.warn(e2);
    getUserPostVotes(myId, post).then((_post) => {
      dispatch(importEunomiaPost(_post));
    }).catch(() => {});
  });
  try{
    nudge.userVoted(post.toJS(), trust? 1:-1);
  }catch(e){
    console.log("Nudge user Voted error", e);
  }
};

export const onEunomiaVote = (post, status, trust, isDelete, isToggle) => (dispatch) => {
  if (accessToken !== null) {
    const sourceId = post.get('source_id', null);
    const sourceUrl = post.get('source_url', null);
    if (sourceId && sourceUrl) {
      const postId = `${sourceId}@${plainDomain(sourceUrl)}`;
      getEunomiaToken().then((data) => {
        if (data) {
          const { token } = data;
          if (isDelete) {
            _deleteVote(post, token, ((success) => {
              if (success) {
                _notifyVoted(sourceId, () => dispatch({ type: CURRENT_USER_VOTED }));
              }
            }));
          } else {
            if (isToggle) {
              _deleteVote(post, token, ((success) => {
                if (success) {
                  _normalVote(token, trust, post, postId, sourceId, dispatch);
                }
              }));
            } else {
              _normalVote(token, trust, post, postId, sourceId, dispatch);
            }
          }
        }
      }).catch((e3) => {
        console.warn(e3);
      });
    }
  }
};
