import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { repository, source_url,accessToken } from 'mastodon/initial_state';
import { logOut } from 'mastodon/utils/log_out';
import { openModal } from 'mastodon/actions/modal';

const messages = defineMessages({
  logoutMessage: { id: 'confirmations.logout.message', defaultMessage: 'Are you sure you want to log out?' },
  logoutConfirm: { id: 'confirmations.logout.confirm', defaultMessage: 'Log out' },
});

const mapDispatchToProps = (dispatch, { intl }) => ({
  onLogout () {
    dispatch(openModal('CONFIRM', {
      message: intl.formatMessage(messages.logoutMessage),
      confirm: intl.formatMessage(messages.logoutConfirm),
      onConfirm: () => logOut(),
    }));
  },
});

export default @injectIntl
@connect(null, mapDispatchToProps)
class LinkFooter extends React.PureComponent {

  static propTypes = {
    withHotkeys: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  handleLogoutClick = e => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onLogout();

    return false;
  }

  render () {
    const { withHotkeys } = this.props;
    let themeName = 'dark';
    const bodyClass = document.body.className;
    window.localStorage.setItem('MASTODON_LOGIN_STATUS', '1');
    if (bodyClass.includes('theme-mastodon-light')) {
      themeName = 'light';
      window.localStorage.setItem('EUNOMIA_MASTODON_THEME', 'light');
    } else {
      window.localStorage.setItem('EUNOMIA_MASTODON_THEME', 'dark');
    }

    return (
      <div className='getting-started__footer'>
        <ul>
          {withHotkeys && <li><Link to='/keyboard-shortcuts'><FormattedMessage id='navigation_bar.keyboard_shortcuts' defaultMessage='Hotkeys' /></Link> · </li>}
          <li><a href='/auth/edit'><FormattedMessage id='getting_started.security' defaultMessage='Security' /></a> · </li>
          <li><a href={`${window.location.origin}/eunomia/preferences/#${accessToken}`} title='Settings' target="blank">Nudge settings</a>· </li>
          <li><a href={`/eunomia/terms?theme=${themeName}`} target='_blank'><FormattedMessage id='getting_started.eunomia.terms' defaultMessage='TRUST FIRST terms & data protection notice' /></a> · </li>
          <li><a href='/auth/sign_out' onClick={this.handleLogoutClick}><FormattedMessage id='navigation_bar.logout' defaultMessage='Logout' /></a></li>
        </ul>
        <p>
          <FormattedMessage
            id='getting_started_eunomia.open_source_notice'
            defaultMessage="TRUST FIRST is powered by EUNOMIA's open source software. You can contribute or report issues on GitLab at {gitlab}."
            values={{ gitlab: <span><a href={source_url} rel='noopener noreferrer' target='_blank'>{repository}</a></span> }}
          />
        </p>
      </div>
    );
  }

};
