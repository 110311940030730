import axios from 'axios';
import {domainName, accessToken} from 'mastodon/initial_state';

var features = {}


var forestjs = (function () {
    var RandomForest = function (options) {
    };
    RandomForest.prototype = {
        /*
        data is 2D array of size N x D of examples
        labels is a 1D array of labels (only -1 or 1 for now). In future will support multiclass or maybe even regression
        options.numTrees can be used to customize number of trees to train (default = 100)
        options.maxDepth is the maximum depth of each tree in the forest (default = 4)
        options.numTries is the number of random hypotheses generated at each node during training (default = 10)
        options.trainFun is a function with signature "function myWeakTrain(data, labels, ix, options)". Here, ix is a list of
                         indeces into data of the instances that should be payed attention to. Everything not in the list
                         should be ignored. This is done for efficiency. The function should return a model where you store
                         variables. (i.e. model = {}; model.myvar = 5;) This will be passed to testFun.
        options.testFun is a function with signature "funtion myWeakTest(inst, model)" where inst is 1D array specifying an example,
                         and model will be the same model that you return in options.trainFun. For example, model.myvar will be 5.
                         see decisionStumpTrain() and decisionStumpTest() downstairs for example.
        */
        train: function (data, labels, options) {
            options = options || {};
            this.numTrees = options.numTrees || 100;
            // initialize many trees and train them all independently
            this.trees = new Array(this.numTrees);
            for (var i = 0; i < this.numTrees; i++) {
                this.trees[i] = new DecisionTree();
                this.trees[i].train(data, labels, options);
            }
        },
        /*
        inst is a 1D array of length D of an example.
        returns the probability of label 1, i.e. a number in range [0, 1]
        */
        predictOne: function (inst) {
            // have each tree predict and average out all votes
            var dec = 0;
            for (var i = 0; i < this.numTrees; i++) {
                dec += this.trees[i].predictOne(inst);
            }
            dec /= this.numTrees;
            return dec;
        },
        // convenience function. Here, data is NxD array.
        // returns probabilities of being 1 for all data in an array.
        predict: function (data) {
            var probabilities = new Array(data.length);
            for (var i = 0; i < data.length; i++) {
                probabilities[i] = this.predictOne(data[i]);
            }
            return probabilities;
        }
    };
    // represents a single decision tree
    var DecisionTree = function (options) {
    };
    DecisionTree.prototype = {
        train: function (data, labels, options) {
            options = options || {};
            var maxDepth = options.maxDepth || 4;
            var weakType = options.type || 0;
            var trainFun = decision2DStumpTrain;
            var testFun = decision2DStumpTest;
            if (options.trainFun)
                trainFun = options.trainFun;
            if (options.testFun)
                testFun = options.testFun;
            if (weakType == 0) {
                trainFun = decisionStumpTrain;
                testFun = decisionStumpTest;
            }
            if (weakType == 1) {
                trainFun = decision2DStumpTrain;
                testFun = decision2DStumpTest;
            }
            // initialize various helper variables
            var numInternals = Math.pow(2, maxDepth) - 1;
            var numNodes = Math.pow(2, maxDepth + 1) - 1;
            var ixs = new Array(numNodes);
            for (var i = 1; i < ixs.length; i++)
                ixs[i] = [];
            ixs[0] = new Array(labels.length);
            for (var i = 0; i < labels.length; i++)
                ixs[0][i] = i; // root node starts out with all nodes as relevant
            var models = new Array(numInternals);
            // train
            for (var n = 0; n < numInternals; n++) {
                // few base cases
                var ixhere = ixs[n];
                if (ixhere.length == 0) {
                    continue;
                }
                if (ixhere.length == 1) {
                    ixs[n * 2 + 1] = [ixhere[0]];
                    continue;
                } // arbitrary send it down left
                // learn a weak model on relevant data for this node
                var model = trainFun(data, labels, ixhere);
                models[n] = model; // back it up model
                // split the data according to the learned model
                var ixleft = [];
                var ixright = [];
                for (var i = 0; i < ixhere.length; i++) {
                    var label = testFun(data[ixhere[i]], model);
                    if (label === 1)
                        ixleft.push(ixhere[i]);
                    else
                        ixright.push(ixhere[i]);
                }
                ixs[n * 2 + 1] = ixleft;
                ixs[n * 2 + 2] = ixright;
            }
            // compute data distributions at the leafs
            var leafPositives = new Array(numNodes);
            var leafNegatives = new Array(numNodes);
            for (var n = numInternals; n < numNodes; n++) {
                var numones = 0;
                for (var i = 0; i < ixs[n].length; i++) {
                    if (labels[ixs[n][i]] === 1)
                        numones += 1;
                }
                leafPositives[n] = numones;
                leafNegatives[n] = ixs[n].length - numones;
            }
            // back up important prediction variables for predicting later
            this.models = models;
            this.leafPositives = leafPositives;
            this.leafNegatives = leafNegatives;
            this.maxDepth = maxDepth;
            this.trainFun = trainFun;
            this.testFun = testFun;
        },
        // returns probability that example inst is 1.
        predictOne: function (inst) {
            var n = 0;
            for (var i = 0; i < this.maxDepth; i++) {
                var dir = this.testFun(inst, this.models[n]);
                if (dir === 1)
                    n = n * 2 + 1; // descend left
                else
                    n = n * 2 + 2; // descend right
            }
            return (this.leafPositives[n] + 0.5) / (this.leafNegatives[n] + 1.0); // bayesian smoothing!
        }
    };

    // returns model
    function decisionStumpTrain(data, labels, ix, options) {
        options = options || {};
        var numtries = options.numTries || 10;
        // choose a dimension at random and pick a best split
        var ri = randi(0, data[0].length);
        var N = ix.length;
        // evaluate class entropy of incoming data
        var H = entropy(labels, ix);
        var bestGain = 0;
        var bestThr = 0;
        for (var i = 0; i < numtries; i++) {
            // pick a random splitting threshold
            var ix1 = ix[randi(0, N)];
            var ix2 = ix[randi(0, N)];
            while (ix2 == ix1)
                ix2 = ix[randi(0, N)]; // enforce distinctness of ix2
            var a = Math.random();
            var thr = data[ix1][ri] * a + data[ix2][ri] * (1 - a);
            // measure information gain we'd get from split with thr
            var l1 = 1, r1 = 1, lm1 = 1, rm1 = 1; //counts for Left and label 1, right and label 1, left and minus 1, right and minus 1
            for (var j = 0; j < ix.length; j++) {
                if (data[ix[j]][ri] < thr) {
                    if (labels[ix[j]] == 1)
                        l1++;
                    else
                        lm1++;
                }
                else {
                    if (labels[ix[j]] == 1)
                        r1++;
                    else
                        rm1++;
                }
            }
            var t = l1 + lm1; // normalize the counts to obtain probability estimates
            l1 = l1 / t;
            lm1 = lm1 / t;
            t = r1 + rm1;
            r1 = r1 / t;
            rm1 = rm1 / t;
            var LH = -l1 * Math.log(l1) - lm1 * Math.log(lm1); // left and right entropy
            var RH = -r1 * Math.log(r1) - rm1 * Math.log(rm1);
            var informationGain = H - LH - RH;
            //console.log("Considering split %f, entropy %f -> %f, %f. Gain %f", thr, H, LH, RH, informationGain);
            if (informationGain > bestGain || i === 0) {
                bestGain = informationGain;
                bestThr = thr;
            }
        }
        var model = {};
        model.thr = bestThr;
        model.ri = ri;
        return model;
    }

    // returns a decision for a single data instance
    function decisionStumpTest(inst, model) {
        if (!model) {
            // this is a leaf that never received any data...
            return 1;
        }
        return inst[model.ri] < model.thr ? 1 : -1;
    }

    // returns model. Code duplication with decisionStumpTrain :(
    function decision2DStumpTrain(data, labels, ix, options) {
        options = options || {};
        var numtries = options.numTries || 10;
        // choose a dimension at random and pick a best split
        var N = ix.length;
        var ri1 = 0;
        var ri2 = 1;
        if (data[0].length > 2) {
            // more than 2D data. Pick 2 random dimensions
            ri1 = randi(0, data[0].length);
            ri2 = randi(0, data[0].length);
            while (ri2 == ri1)
                ri2 = randi(0, data[0].length); // must be distinct!
        }
        // evaluate class entropy of incoming data
        var H = entropy(labels, ix);
        var bestGain = 0;
        var bestw1, bestw2, bestthr;
        var dots = new Array(ix.length);
        for (var i = 0; i < numtries; i++) {
            // pick random line parameters
            var alpha = randf(0, 2 * Math.PI);
            var w1 = Math.cos(alpha);
            var w2 = Math.sin(alpha);
            // project data on this line and get the dot products
            for (var j = 0; j < ix.length; j++) {
                dots[j] = w1 * data[ix[j]][ri1] + w2 * data[ix[j]][ri2];
            }
            // we are in a tricky situation because data dot product distribution
            // can be skewed. So we don't want to select just randomly between
            // min and max. But we also don't want to sort as that is too expensive
            // let's pick two random points and make the threshold be somewhere between them.
            // for skewed datasets, the selected points will with relatively high likelihood
            // be in the high-desnity regions, so the thresholds will make sense
            var ix1 = ix[randi(0, N)];
            var ix2 = ix[randi(0, N)];
            while (ix2 == ix1)
                ix2 = ix[randi(0, N)]; // enforce distinctness of ix2
            var a = Math.random();
            var dotthr = dots[ix1] * a + dots[ix2] * (1 - a);
            // measure information gain we'd get from split with thr
            var l1 = 1, r1 = 1, lm1 = 1, rm1 = 1; //counts for Left and label 1, right and label 1, left and minus 1, right and minus 1
            for (var j = 0; j < ix.length; j++) {
                if (dots[j] < dotthr) {
                    if (labels[ix[j]] == 1)
                        l1++;
                    else
                        lm1++;
              }
              else {
                    if (labels[ix[j]] == 1)
                        r1++;
                    else
                        rm1++;
                }
            }
            var t = l1 + lm1;
            l1 = l1 / t;
            lm1 = lm1 / t;
            t = r1 + rm1;
            r1 = r1 / t;
            rm1 = rm1 / t;
            var LH = -l1 * Math.log(l1) - lm1 * Math.log(lm1); // left and right entropy
            var RH = -r1 * Math.log(r1) - rm1 * Math.log(rm1);
            var informationGain = H - LH - RH;
            //console.log("Considering split %f, entropy %f -> %f, %f. Gain %f", thr, H, LH, RH, informationGain);
            if (informationGain > bestGain || i === 0) {
                bestGain = informationGain;
                bestw1 = w1;
                bestw2 = w2;
                bestthr = dotthr;
            }
        }
        var model = {};
        model.w1 = bestw1;
        model.w2 = bestw2;
        model.dotthr = bestthr;
        return model;
    }

    // returns label for a single data instance
    function decision2DStumpTest(inst, model) {
        if (!model) {
            // this is a leaf that never received any data...
            return 1;
        }
        return inst[0] * model.w1 + inst[1] * model.w2 < model.dotthr ? 1 : -1;
    }

    // Misc utility functions
    function entropy(labels, ix) {
        var N = ix.length;
        var p = 0.0, q;
        for (var i = 0; i < N; i++) {
            if (labels[ix[i]] == 1)
                p += 1;
        }
        p = (1 + p) / (N + 2); // let's be bayesian about this
        q = (1 + N - p) / (N + 2);
        return (-p * Math.log(p) - q * Math.log(q));
    }

    // generate random floating point number between a and b
    function randf(a, b) {
        return Math.random() * (b - a) + a;
    }

    // generate random integer between a and b (b excluded)
    function randi(a, b) {
        return Math.floor(Math.random() * (b - a) + a);
    }

    // export public members
    var exports = {};
    exports.DecisionTree = DecisionTree;
    exports.RandomForest = RandomForest;
    exports.forestjs = forestjs;
    return exports;
})();

class Nudge {
    constructor(forestjs) {
        this.DOMAIN_NAME = `https://${domainName}`; // Substitute for the Domain Name of the current API
        this.MASTODON_TOKEN = accessToken; // Auth token from the user
        this.STORAGE_SERVER = `https://${domainName}/storage`;
        this.forestjs = forestjs;
        console.log(">> init nudge", this.DOMAIN_NAME, this.STORAGE_SERVER, this.MASTODON_TOKEN)
    }


    getEunomiaToken(mast) {
        const url = `${this.DOMAIN_NAME}/eunomia/api/credentials?sn=mastodon&sn_url=${this.DOMAIN_NAME}&sn_token=${mast}`;
        return axios.get(url)
    }

// ============================ Gets Features ============================== //
    getFeatures(data) {
        // follow ratio

        features["postid"] = data.source_id
        features["word_share_used"] = /share/.test(data.text) ? 1 : 0
        features["exclamation_marks_used"] = /!/.test(data.text) ? 1 : 0
        features["no_capitalised_words"] = data.text.match(/(\b[A-Z][A-Z]+|\b[A-Z]\b)/g) ? 1 : 0
        features["url_used"] = /https/.test(data.text) ? 1 : 0
        if (data.image_vectors == null) {
            features["image_used"] = 0
        }
        else {
            features["image_used"] = 1
        }
        features["trust_votes_ratio"] = data.votes.trusts / (data.votes.mistrusts + data.votes.trusts) // control for 0
        features["upvotes"] = data.votes.trusts
        features["downvotes"] = data.votes.mistrusts
        features["followingCount"] = data.original_post.account.following_count
        features["followerCount"] = data.original_post.account.followers_count
        features["follow_ratio"] = data.original_post.account.followers_count / data.original_post.account.following_count
        features["statuses_count"] = data.original_post.account.statuses_count
        features["reblogs_count"] = data.original_post.reblogs_count
        features["positive_class"] = 0
        features["neutral_class"] = 0
        features["negative_class"] = 0
        features["hasSimilarLookingPosts"] = 0
        if (data.cascade_id != null) {
            features["hasSimilarLookingPosts"] = 1
        }

        if (data.sentiment_class == 1) {
            features["positive_class"] = 1
        }
        else if (data.sentiment_class == 0) {
            features["neutral_class"] = 1
        }
        else if (data.sentiment_class == -1) {
            features["negative_class"] = 1
        }
        if (data.subjectivity_score > 0.5) {
            features["subjectivity_class"] = 1
        }
        else {
            features["subjectivity_class"] = 0
        }


        features["accountAge"] = 0
        var myDate = new Date(data.original_post.account.created_at);
            myDate = (Date.now() - myDate.getTime()) / 1000;
        if (myDate < 86400) { //1d
            features["accountAge"] = 0
        }
        if (myDate < 432000) { //1w
            features["accountAge"] = 1
        }
        else if (myDate < 2592000) { //1m
            features["accountAge"] = 2
        }
        else if (myDate < 31104000) { //1y
            features["accountAge"] = 3
        }
        else { // More
            features["accountAge"] = 4
        }

        features["postingFrequency"] = 0 //same as before, but in days
        var myDate = new Date(data.original_post.account.created_at);
        myDate = (Date.now() - myDate.getTime()) /1000;
        if (myDate < 86400) { //1d
            features["postingFrequency"] = 0
        }
        else if (myDate < 2592000) { //1m
            features["postingFrequency"] = 1
        }
        else if (myDate < 31104000) { //1y
            features["postingFrequency"] = 2
        }
        else { // More
            features["postingFrequency"] = 3
        }
        return features
    }

// ============================ Trains ML Model ============================== //
    trainForest(features, votes) {

        if (features.lenght != votes.lenght) {
            console.log("Features and Votes do not have the same size, forest can not be built")
            return false
        }

        delete features["postid"]; // Clear the ID so it does not clash with the proper features

        var options = {};
        options.numTrees = 50; // defaults
        options.maxDepth = 10;
        options.numTries = 4; //sqrt(features)


        var forestobj = new forestjs.RandomForest()
        // data is 2D array of size NxD. Labels is 1D array of length D
        forestobj.train(features, votes, options);

        return forestobj
    }

    predictStatic(features, parameters) {
        console.log("Features from the post", features)
        console.log("Parameters from the preferences", parameters)

        var post_score = 0

        for (const key in parameters) {
            if (parameters[key].enabled == false) {
                delete parameters[key]
            }
        }

        for (const key in parameters) {
            switch (key) {

                case "accountAge":

                    if (features.accountAge <= parameters[key].value) {
                        post_score++
                    }
                    break;

                case "postingFrequency":
                    if (features.postingFrequency <= parameters[key].value) {
                        post_score++
                    }
                    break;

                case "hasSimilarLookingPosts":
                    if (features.hasSimilarLookingPosts == 1) {
                        post_score++
                    }
                    break;

                case "followerCount":
                    if (features.followerCount < 10) {
                        features["followerCount"] = 0
                    }
                    else if (features.followerCount < 100) {
                        features["followerCount"] = 1
                    }
                    else {
                        features["followerCount"] = 2
                    }

                    if (features.followerCount <= parameters[key].value) {
                        post_score++
                    }
                    break;

                case "followingCount":
                    if (features.followingCount < 100) {
                        features["followingCount"] = 0
                    }
                    else if (features.followingCount < 1000) {
                        features["followingCount"] = 1
                    }
                    else {
                        features["followingCount"] = 2
                    }

                    if (features.followingCount <= parameters[key].value) {
                        post_score++
                    }
                    break;
                case "trust_votes_ratio":
                    if (features.trust_votes_ratio < 0.05) {
                        features["trust_votes_ratio"] = 0
                    }
                    else if (features.trust_votes_ratio < 0.1) {
                        features["trust_votes_ratio"] = 1
                    }
                    else if (features.trust_votes_ratio < 0.25) {
                        features["trust_votes_ratio"] = 2
                    }
                    else if (features.trust_votes_ratio < 0.5) {
                        features["trust_votes_ratio"] = 3
                    }
                    else {
                        features["trust_votes_ratio"] = 4
                    }

                    if (features.trust_votes_ratio <= parameters[key].value) {
                        post_score++
                    }
                    break;
            }
        }

        let size = Object.keys(parameters).length
        post_score = post_score / size

  console.log("post score is",post_score)

        if (post_score >= 0.5) {
            return ["nudge", post_score]
        }
        else {
            return ["do not nudge", post_score]
        }
    }


    storageToken(token) {
        var url = `${this.STORAGE_SERVER}/token?access_token=${token}&auth_provider_uri=${this.DOMAIN_NAME}`
        return axios.get(url)
    }

    writeObject(features, vote, userId, STORAGE_TOKEN, postid) {
        // Storage Server Query for Ballot with that post ID. If there is, delete the previous one
        this.getBallot(userId, STORAGE_TOKEN, postid).then(response => {
        console.log("there are",Object.keys(response.data.data).length,"ballots for the selected post id")
            if (response.data.data[0] == undefined) {
                console.log("There is no post ballot for the settings")
        }
        else {
                console.log(response.data.data)
                var idlist = []
                for (var i = 0; i < response.data.data.length; i++) {
                    idlist.push(response.data.data[i].id)
                }
                for (var i = 0; i < idlist.length; i++) {
                    this.deleteObject(idlist[i], STORAGE_TOKEN)
                }
            }
        })


        // Write the post Ballot
        axios.post(`${this.STORAGE_SERVER}/objects?access_token=${STORAGE_TOKEN}`, {
            "type": "trust-votes",
            "properties": {
                "feature_dict": features,
                "vote": vote,
                "userId": userId
            }
        }).then((response) => {
            console.log("Trust Vote Object Was Created");
        }, (error) => {
            console.log(error)
            console.log("Object was not Created");
        });
    }

    deleteObject(id, STORAGE_TOKEN) {
        axios.delete(`${this.STORAGE_SERVER}/objects/${id}?access_token=${STORAGE_TOKEN}`)
    }

    writeForest(forest, userId, STORAGE_TOKEN) {
        axios.post(`${this.STORAGE_SERVER}/objects?access_token=${STORAGE_TOKEN}`, {
            "type": "dynamic_nudge_object",
            "properties": {
                "forest": forest,
                "userId": userId
            }
        }).then((response) => {
            console.log(response.data.message);
        }, (error) => {
            console.log("Object was not Created");
        });
    }

    getPosts(userId, STORAGE_TOKEN) {
        var url = `${this.STORAGE_SERVER}/objects?property=properties.userId&property=type&comp=eq&comp=eq&value=${userId}&value=trust-votes&access_token=${STORAGE_TOKEN}`
        return axios.get(url)
    }

    getForest(userId, STORAGE_TOKEN) {
        var url = `${this.STORAGE_SERVER}/objects?property=properties.userId&property=type&comp=eq&comp=eq&value=${userId}&value=dynamic_nudge_object&access_token=${STORAGE_TOKEN}`
        return axios.get(url)
    }

    getBallot(userId, STORAGE_TOKEN, postid) {
        var url = `${this.STORAGE_SERVER}/objects?property=properties.userId&property=type&property=properties.feature_dict.postid&comp=eq&comp=eq&comp=eq&value=${userId}&value=trust-votes&value=${postid}&access_token=${STORAGE_TOKEN}`
        return axios.get(url)
    }

    deleteForest(userId, STORAGE_TOKEN) {
        this.getForest(userId, STORAGE_TOKEN).then(response => {
            console.log(response)
            if (response.data.data[0] == undefined) {
                console.log("There is no forest for the current user")
            }
            else {
                console.log(response.data.data)
                var idlist = []
                for (var i = 0; i < response.data.data.length; i++) {
                    idlist.push(response.data.data[i].id)
                }
                for (var i = 0; i < idlist.length; i++) {
                    this.deleteObject(idlist[i], STORAGE_TOKEN)
                }
            }
        })
    }

    getConfig(userId, STORAGE_TOKEN) {
        var url = `${this.STORAGE_SERVER}/objects?property=properties.userId&property=type&comp=eq&comp=eq&value=${userId}&value=nudge_preferences&access_token=${STORAGE_TOKEN}`
        return axios.get(url)
    }

    predictDynamic(forest, features_array) {
        var prob = window.forestObj.predictOne(features_array)
        var trust = ""
        if (prob < 0.50) {
            trust = "nudge"
        }
        else if (prob >= 0.5) {
            trust = "do not nudge"
        }
        return [trust, prob]
    }

    dictToArray(dict) {
        var array = []
        for (var key in dict) {
            array.push(dict[key]);
        }
        return array
    }

    trainWorkflow(response, STORAGE_TOKEN, userId) {
        var n_posts = Object.keys(response.data.data).length
        var features_array = []
        var votes_array = []
        for (var i = 0; i < n_posts; i++) {
            var arr = []
            for (var key in response.data.data[i].properties.feature_dict) {
                arr.push(response.data.data[i].properties.feature_dict[key]);
            }
            features_array.push(arr)
            votes_array.push(response.data.data[i].properties.vote)
        }
        var forest_obj = this.trainForest(features_array, votes_array);
        window.forestObj = forest_obj;
        console.log("Forest Generated")
        this.deleteForest(userId, STORAGE_TOKEN)
        this.writeForest(forest_obj, userId, STORAGE_TOKEN)
    }

    enabled() {
        return 'true' === window.localStorage.getItem('nudge-enabled');
    }

// ============================================================================= //
//                   Workflow 1 - When a User Votes on a Post                    //
// ============================================================================= //

// MASTODON_TOKEN = MASTODON_TOKEN // Mastodon Token from TRIGGER
// POST = post //GET FROM TRIGGER
// VOTE = 1 OR -1

    userVoted(post, VOTE) {
        if (this.enabled()) {
            this.getEunomiaToken(accessToken).then(response => {
                var token = response.data.eunomia_token
                var userId = response.data.account_url
                var features = this.getFeatures(post)
                var STORAGE_TOKEN = token;
                console.log(`Eunomia Token adquired = ${token} from user ${userId}`)
                this.writeObject(features, VOTE, userId, STORAGE_TOKEN, post.source_id) // Finishes Writing Post Vote Object in Storage Server
                this.retrain(userId, STORAGE_TOKEN);
            })
        }
    }

// ============================================================================= //
//                        Workflow 2 - When a User Logs in                       //
// ============================================================================= //

// MASTODON_TOKEN = MASTODON_TOKEN // Mastodon Token from TRIGGER
    userLogs() {
        this.getEunomiaToken(accessToken).then(response => {
            var token = response.data.eunomia_token;
            var userId = response.data.account_url;
            var STORAGE_TOKEN = token
            console.log(`Eunomia Token adquired = ${token} from user ${userId}`)
            this.getConfig(userId, STORAGE_TOKEN).then((response) => {
                console.log("Nudge :: User preferences loaded: ", response)
                if (response.data && response.data.data && response.data.data.length > 0) {
                    var preferences = response.data.data[response.data.data.length - 1].properties;
                    console.log( "User preferences are", preferences);
                    window.localStorage.setItem('nudge-enabled', preferences.nudgeEnabled);
                    if (preferences.AI_Settings == 'ai') {
                        this.getForest(userId, STORAGE_TOKEN).then((response) => {
                            var model = response.data.data[0] // Somehow this gets saved in the webpage so it does not have to be re-cached every time. I am unsure on how this works
                            window.localStorage.setItem("model", JSON.stringify({"data": model})) // Change to session storage later
                            var model_type = "dynamic_nudge_object"
                            window.localStorage.setItem("model_type", model_type);
                            this.retrain(userId, STORAGE_TOKEN);
                        })
                    } else if (preferences.nudgeEnabled == true) {
                        var model_type = "static_nudge_object"
                        window.localStorage.setItem("model_type", model_type);
                        window.localStorage.setItem("model", JSON.stringify({"data": preferences.trustworthinessIndicators}));
                    } else {
                        console.log("There is no Machine Learning model for this user")
                    }
                } else {
                    if( !window.forestObj ){
                        window.localStorage.setItem('nudge-enabled', "false");
                    }
                    console.log("There is no model for this user")
                }
            })
        })

        if (!window.nudgeReady) {
            window.onstorage = event => { // same as window.addEventListener('storage', event => {
                if (event.key != 'nudge-updates') return;
                window.location.reload();
            };
            window.nudgeReady = true;
        }
    }

// ============================================================================= //
//                  Workflow 3 - When a Post appears on the timeline             //
// ============================================================================= //

// The Machine Learning model is saved from workflow two as SessionStorage   //

    postAppears(post) {
        let prediction = '-';
        let model = JSON.parse(window.localStorage.getItem("model"));
        if (this.enabled() && model && model.data) {
            let model_type = window.localStorage.getItem("model_type")
            let features = this.getFeatures(post);
            if (model_type == "static_nudge_object") {
                prediction = this.predictStatic(features, model.data)
            } else if (model_type == "dynamic_nudge_object") {
                var features_array = this.dictToArray(features)
                prediction = this.predictDynamic(model.data.properties.forest, features_array)
            }
        }
        return prediction;
    }

// ============================================================================= //
//                  Workflow 4 - When Settings are updated                       //
// ============================================================================= //

// Trigger when settings page is updated
    settingsUpdate() {
        this.userLogs()
    }

    retrain(userId, STORAGE_TOKEN) {
        var starttime = Date.now() // @Timer
        this.getPosts(userId, STORAGE_TOKEN,).then(response => {
            if (response.data.data.length > 0) {
                var n_posts = Object.keys(response.data.data).length
                console.log(`Returned ${n_posts} posts`)
                var votes_dict = {"trust": 0, "non_trust": 0}
                for (const [key, value] of Object.entries(response.data.data)) {
                    if (value.properties.vote == -1) {
                        votes_dict["non_trust"] = votes_dict["non_trust"] + 1
                    } else {
                        votes_dict["trust"] = votes_dict["trust"] + 1
                    }
                }

                this.getForest(userId, STORAGE_TOKEN).then(responseX => {
                    var dynamic_bool = true
                    if (responseX.data.data == null || responseX.data.data.length == 0) { // Checks if the user already has a dynamic model, as rules are different for both scenarios
                        dynamic_bool = false
                    }
                    console.log("Checks if the user already has a dynamic model:", dynamic_bool);

                    // Train / Re-train conditions

                    if (dynamic_bool == false) { // Condition for a user that does not still have a dynamic model
                        console.log("At Least 30 Posts (Condition A):", n_posts, n_posts > 30);
                        if (n_posts >= 30) { // At Least 30 Posts (Condition A)
                            console.log("At Least 50% of the votes have to be trusts", votes_dict["trust"], votes_dict["non_trust"], votes_dict["trust"] / (votes_dict["trust"] + votes_dict["non_trust"]) >= 0.5);
                            if (votes_dict["trust"] / (votes_dict["trust"] + votes_dict["non_trust"]) >= 0.5) { // At Least 50% of the votes have to be trusts (Condition B)
                                this.trainWorkflow(response, STORAGE_TOKEN, userId) // If Condition A and B are met, Train Model
                            }
                        }
                    }

                    if (dynamic_bool == true) { // Condition for a user that already has a dynamic model
                        if (n_posts > 30) { // Check for retraining every 30 posts (Condition A)
                            if (votes_dict["trust"] / (votes_dict["trust"] + votes_dict["non_trust"]) >= 0.35) { // At Least 50% of the votes have to be trusts (Condition B)
                                this.trainWorkflow(response, STORAGE_TOKEN, userId) // If Condition A and B are met, Train Model
                            }
                        }
                    }
                })
            }
            console.log(`Function userVoted took ${Date.now() - starttime}ms`) // @Timer
        })
    }
}

let nudge = new Nudge(forestjs)
export default nudge;